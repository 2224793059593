// src/Components/Home/Home.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  FaTools,
  FaCogs,
  FaInfinity,
  FaCopy,
  FaBook, // Added for documentation icon
} from 'react-icons/fa';
import { GiPowerLightning } from 'react-icons/gi';
import { MdBusiness } from 'react-icons/md';
import { FaHandshakeSimple } from 'react-icons/fa6';
import { SiPypi } from 'react-icons/si'; // PyPI icon

// Reusable Navbar
import Navbar from '../ReusableComponents/Navbar';

// Framer Motion fadeInUp
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: (i = 0) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.7,
      delay: i * 0.1,
      ease: 'easeOut',
    },
  }),
};

// Reusable CopyButton Component
const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      // Reset the copied state after 2 seconds
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy!', err);
      // Optionally, add user feedback for the error here
    }
  };

  return (
    <>
      {/* Copy Button for Medium and Larger Screens */}
      <button
        onClick={handleCopy}
        className="absolute top-2 right-2 text-xs px-2 py-1 
                  bg-[#3C4043] text-gray-200 hover:bg-blue-600 hover:text-white 
                  rounded transition-colors flex items-center justify-center
                  md:block hidden" // Visible on medium screens and up
        aria-label="Copy pip install command"
      >
        {copied ? 'Copied!' : <FaCopy size={12} />}
      </button>
      
      {/* Copy Button for Small Screens */}
      <button
        onClick={handleCopy}
        className="absolute top-2 right-2 text-xs px-2 py-1 
                  bg-[#3C4043] text-gray-200 hover:bg-blue-600 hover:text-white 
                  rounded transition-colors flex items-center justify-center
                  md:block" // Visible on medium screens and up
        aria-label="Copy pip install command"
      >
        {copied ? 'Copied!' : <FaCopy size={12} />}
      </button>
    </>
  );
};

function Home() {
  // Contact form state
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    message: '',
    type: 'developer',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      // Send a POST request to FormSpark with the form data
      const response = await fetch('https://submit-form.com/l21tuwxSZ', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Specify JSON format
        },
        body: JSON.stringify(contactForm), // Convert form data to JSON
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // If the submission is successful
      alert('Thank you for reaching out!'); // Alert the user
      setContactForm({
        name: '',
        email: '',
        message: '',
        type: 'developer',
      }); // Clear the form fields
      
    } catch (error) {
      /// If the submission fails
      alert('An error occurred. Please try again later.'); // Alert the user
      console.error('Form submission error:', error);
      // Optionally, you can set an error state to display a message in the UI
    }
  };

  return (
    <div className="min-h-screen flex bg-gradient-to-b from-[#0B0F10] to-[#05080A] text-[#EAEAEA]">
      {/* Sidebar */}
      <div className="bg-[#0E1112] flex-shrink-0">
        <Navbar textColor="#ffffff" accentColor="#00BFA6" />
      </div>

      {/* Main Content Area */}
      <div className="flex-1 overflow-auto">
        {/* TITLE SECTION */}
        <motion.section
          className="
            bg-[#131819] rounded-xl shadow-neomorphic
            p-6 m-6
            flex flex-col items-start
          "
          variants={fadeInUp}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          {/* Title & Subtitle */}
          <div>
            <h1
              className="
                text-3xl md:text-4xl font-extrabold text-[#00BFA6]
                border-l-4 border-[#00BFA6] pl-3
              "
            >
              DESYNC.AI - API TO THE INTERNET
            </h1>
            <p className="mt-3 text-sm md:text-base opacity-80 font-semibold max-w-3xl">
              A universal data extraction solution.
              Everything you’ve ever wanted from a web scraper but never got.
            </p>
          </div>
        </motion.section>

        {/* MAIN GRID SECTION (2 columns) */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
          {/* LEFT COLUMN: API TO THE INTERNET */}
          <motion.section
            className="
              bg-[#131819] rounded-xl shadow-neomorphic
              p-6 flex flex-col
            "
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
          >
            {/* Quick Start with pip install + PyPI icon */}
            <div
              className="
                bg-[#1A1F22] p-4 rounded-xl
                border border-[#2C2C2C]
                shadow-neomorphic
                relative
              "
            >
              <h3 className="text-lg font-bold mb-2 flex items-center gap-2">
                <SiPypi size={24} className="text-[#8DB600]" />
                Quick Start
              </h3>
              <div className="relative">
                <code className="bg-[#0E1112] p-3 rounded text-xs md:text-sm block">
                  pip install desync_search
                </code>
                {/* Copy Buttons */}
                <CopyButton text="pip install desync_search" />
              </div>
              {/* Documentation Link */}
              <a
                href="https://pypi.org/project/desync-search/"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 inline-flex items-center text-xs px-3 py-2 
                           bg-[#3C4043] text-gray-200 hover:bg-blue-600 hover:text-white 
                           rounded transition-colors"
              >
                <FaBook size={14} className="mr-1" />
                See Full Documentation
              </a>
            </div>

            {/* Pricing and API Key Section */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-6">
              {/* Pricing Line 1 */}
              <motion.div
                className="
                  bg-[#1A1F22] p-4 rounded-xl
                  shadow-neomorphic hover:shadow-neomorphic-inset
                  flex flex-col justify-center
                "
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
              >
                <p className="text-sm md:text-base opacity-75">$1 = 10,000 Credits</p>
              </motion.div>

              {/* Pricing Line 2 */}
              <motion.div
                className="
                  bg-[#1A1F22] p-4 rounded-xl
                  shadow-neomorphic hover:shadow-neomorphic-inset
                  flex flex-col justify-center
                "
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
              >
                <p className="text-sm md:text-base opacity-75">1 Credit = 1 Test Search</p>
              </motion.div>

              {/* Pricing Line 3 */}
              <motion.div
                className="
                  bg-[#1A1F22] p-4 rounded-xl
                  shadow-neomorphic hover:shadow-neomorphic-inset
                  flex flex-col justify-center
                "
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
              >
                <p className="text-sm md:text-base opacity-75">10 Credits = 1 Stealth Search</p>
              </motion.div>

              {/* Get Your API Key Box */}
              <motion.div
                className="
                  bg-[#1A1F22] p-4 rounded-xl
                  shadow-neomorphic hover:shadow-neomorphic-inset
                  flex flex-col justify-center items-center
                "
                variants={fadeInUp}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
              >
                <a
                  href="https://beta.desync.ai/login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    className="
                      border border-[#00BFA6] text-gray-400 px-6 py-2 rounded shadow hover:bg-[#00BFA6] 
                      hover:text-white transition duration-300 ease-in-out
                    "
                  >
                    Get An API Key
                  </button>
                </a>
              </motion.div>
            </div>
          </motion.section>

          {/* RIGHT COLUMN: DEV FRIENDLY / FAST / LIMITLESS */}
          <div className="flex flex-col gap-6">
            {/* Dev Friendly (Yellow) */}
            <motion.div
              className="
                bg-[#131819] p-4 rounded-xl
                shadow-neomorphic hover:shadow-neomorphic-inset
                flex items-center gap-4
              "
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
            >
              <FaHandshakeSimple className="text-yellow-400" size={40} />
              <div>
                <h3 className="text-xl font-bold text-yellow-400">
                  Dev Friendly
                </h3>
                <p className="text-sm mt-1 text-yellow-400">
                  4 lines of Python
                </p>
              </div>
            </motion.div>

            {/* Fast (Red) */}
            <motion.div
              className="
                bg-[#131819] p-4 rounded-xl
                shadow-neomorphic hover:shadow-neomorphic-inset
                flex items-center gap-4
              "
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
            >
              <GiPowerLightning className="text-red-500" size={40} />
              <div>
                <h3 className="text-xl font-bold text-red-500">Fast</h3>
                <p className="text-sm mt-1 text-red-500">
                  500+ concurrent ops
                </p>
              </div>
            </motion.div>

            {/* Limitless (Apple Green #8DB600) */}
            <motion.div
              className="
                bg-[#131819] p-4 rounded-xl
                shadow-neomorphic hover:shadow-neomorphic-inset
                flex items-center gap-4
              "
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
            >
              <FaInfinity className="text-[#8DB600]" size={40} />
              <div>
                <h3 className="text-xl font-bold text-[#8DB600]">Limitless</h3>
                <p className="text-sm mt-1 text-[#8DB600]">
                  1,000,000+ pages extracted
                </p>
              </div>
            </motion.div>
          </div>
        </div>

        {/* NEXT SECTION: CORE / TOOLS / TEMPLATES */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-6 pb-6 mt-6">
          {/* CORE */}
          <motion.section
            className="
              bg-[#131819] rounded-xl shadow-neomorphic p-6
              flex flex-col justify-between
            "
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
          >
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-2xl font-bold text-blue-400">Core</h3>
              <FaCogs size={30} className="text-blue-400" />
            </div>
            <p className="text-sm opacity-75">
              Stealth, concurrency, DOM traversal for robust extraction.
            </p>
            <a href="/core">
              <button
                className="
                  mt-4 bg-blue-400 text-white px-6 py-2
                  rounded-lg font-semibold shadow-lg
                  transition-transform hover:scale-105
                "
              >
                Learn More
              </button>
            </a>
          </motion.section>

          {/* TOOLS */}
          <motion.section
            className="
              bg-[#131819] rounded-xl shadow-neomorphic p-6
              flex flex-col justify-between
            "
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
          >
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-2xl font-bold text-orange-400">Tools</h3>
              <FaTools size={30} className="text-orange-400" />
            </div>
            <p className="text-sm opacity-75">
              Specialized ops: forms, button clicks, chaining, etc.
            </p>
            <a href="/tools">
              <button
                className="
                  mt-4 bg-orange-400 text-white px-6 py-2
                  rounded-lg font-semibold shadow-lg
                  transition-transform hover:scale-105
                "
              >
                Learn More
              </button>
            </a>
          </motion.section>

          {/* TEMPLATES */}
          <motion.section
            className="
              bg-[#131819] rounded-xl shadow-neomorphic p-6
              flex flex-col justify-between
            "
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
          >
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-2xl font-bold text-purple-400">Templates</h3>
              <FaInfinity size={30} className="text-purple-400" />
            </div>
            <p className="text-sm opacity-75">
              Pre-built workflows for faster repetitive tasks.
            </p>
            <a href="/templates">
              <button
                className="
                  mt-4 bg-purple-400 text-white px-6 py-2
                  rounded-lg font-semibold shadow-lg
                  transition-transform hover:scale-105
                "
              >
                Coming Soon!
              </button>
            </a>
          </motion.section>
        </div>

        {/* CONTACT FORM */}
        <motion.section
          className="
            bg-[#131819] rounded-xl shadow-neomorphic
            p-6 mx-6 mb-6
          "
          variants={fadeInUp}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          <div
            className="
              bg-[#1A1F22] p-6 rounded-xl
              shadow-neomorphic hover:shadow-neomorphic-inset
              transition-all
            "
          >
            {/* Header */}
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
              <div>
                <h3 className="text-2xl font-bold mb-2">Contact / Partner</h3>
                <p className="text-sm md:text-base opacity-75">
                  We’d love to collaborate—whether you're a dev, investor,
                  integrator, or just have feature ideas. Fill out the form below.
                </p>
              </div>
              <MdBusiness size={40} className="text-[#00BFA6] mt-4 md:mt-0" />
            </div>

            {/* The Contact Form */}
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-1 md:grid-cols-2 gap-6"
            >
              {/* Left Side */}
              <div className="flex flex-col space-y-4">
                <div>
                  <label
                    className="block text-sm font-semibold mb-1"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="
                      w-full p-2 rounded-md bg-[#0E1112]
                      border border-[#2C2C2C]
                      focus:outline-none focus:ring-2 focus:ring-[#00BFA6]
                    "
                    value={contactForm.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-semibold mb-1"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="
                      w-full p-2 rounded-md bg-[#0E1112]
                      border border-[#2C2C2C]
                      focus:outline-none focus:ring-2 focus:ring-[#00BFA6]
                    "
                    value={contactForm.email}
                    onChange={handleChange}
                    placeholder="you@example.com"
                    required
                  />
                </div>
                <div>
                  <label
                    className="block text-sm font-semibold mb-1"
                    htmlFor="type"
                  >
                    I am a(n):
                  </label>
                  <select
                    id="type"
                    name="type"
                    className="
                      w-full p-2 rounded-md bg-[#0E1112]
                      border border-[#2C2C2C]
                      focus:outline-none focus:ring-2 focus:ring-[#00BFA6]
                    "
                    value={contactForm.type}
                    onChange={handleChange}
                  >
                    <option value="developer">Developer</option>
                    <option value="investor">Investor</option>
                    <option value="integration">Integrator</option>
                    <option value="features">Feature Request</option>
                  </select>
                </div>
              </div>

              {/* Right Side */}
              <div className="flex flex-col space-y-4">
                <div className="flex-1">
                  <label
                    className="block text-sm font-semibold mb-1"
                    htmlFor="message"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="5"
                    className="
                      w-full p-2 rounded-md bg-[#0E1112]
                      border border-[#2C2C2C]
                      focus:outline-none focus:ring-2 focus:ring-[#00BFA6]
                    "
                    value={contactForm.message}
                    onChange={handleChange}
                    placeholder="Tell us more..."
                    required
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="
                      bg-[#00BFA6] text-white px-6 py-2
                      rounded-lg font-semibold shadow-lg
                      transition-transform hover:scale-105
                    "
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </motion.section>
      </div>
    </div>
  );
}

export default Home;
